import React from "react";
import ReactDOM from "react-dom";
import configStore from "./store";
import WebApp from "./WebApp";
import { Provider } from "react-redux";
import "./i18n";

const store = configStore();

ReactDOM.render(
  React.createElement(
    Provider,
    { store: store },
    React.createElement(WebApp, null)
  ),
  document.getElementById("webapp")
);

import styled from "styled-components";

export const TopBar = styled.div`
  position: absolute; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  background-color: white;
  height: 60px;
  z-index: 100;
`;

export const TopBarContent = styled.div`
  text-align: center;
  vertical-align: middle;
  color: black;
  font-size: 16pt;
  margin-top: 1rem;
  width: auto;
  overflow: hidden;
  padding: 0;

  ${(props) => {
    switch (props.device) {
      case "mobile":
        return "height: calc(100vh - 30vh + 10px);";
      case "browser":
        return "margin-left:-30px;";
    }
  }}
`;

export const LandingBody = styled.div`
  width: 100%; /* Full width */
  margin-top: 60px;

  ${(props) => {
    switch (props.page) {
      case "pink":
        return "background-color: #f5b5b5;";
      case "grey":
        return "background-color: #f1f1f1;";
    }
  }}
  ${(props) => {
    switch (props.device) {
      case "mobile":
        return "height: calc(100vh - 30vh + 10px);";
      case "browser":
        return "height: calc(100vh - 60px + 10px);";
    }
  }}
`;

export const WelcomeImage = styled.img`
  /* max-width: 53%; */
  height: auto;
  padding-top: 15px;
  margin-bottom: -110px;
  position: relative;
  z-index: 1;
  /* margin-left: 22%; */
  ${(props) => {
    switch (props.device) {
      case "mobile":
        return "max-width: 70%; margin-left: 15%;";
      case "browser":
        return "max-width: 53%; margin-left: 22%;";
    }
  }}
`;

export const LandingBodyBox = styled.div`
  border-radius: 25px;
  background-color: white;
  padding: 20px;
  width: 100%;
  z-index: 0;
`;

export const LandingBodyBoxContent = styled.div`
  margin-top: 80px;
  font-size: 13pt;
`;

export const QuestionnaireBodyBox = styled.div`
  border-radius: 25px;
  background-color: #fff2f3;
  width: 100%;
  height: auto;
  position: relative;
  z-index: 1;
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.15);
`;

export const QuestionnaireBodyBoxTopContent = styled.div`
  font-size: 13pt;
  padding: 20px;
  height: calc(100vh - 400px);
  /* overflow: auto; */

  overflow-y: scroll;
`;

export const QuestionnaireBodyBoxBottomContent = styled.div`
  padding: 20px;
  height: 35%;
  background-color: white;
  border-radius: 0 0 25px 25px;
`;

export const QuestionsTitle = styled.div`
  font-size: 13pt;
`;

export const TextInput = styled.div`
  border-radius: 25px;
  border: 2px solid #609;
  padding: 20px;
  width: 35%;
  height: 15px;
`;

export const Centralise = styled.div`
  text-align: center;
`;

export const Button = styled.button`
  background-color: #f5b5b5;
  border-radius: 5px;
  border: none;
  color: white;
  padding: 5px 9px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 100%;
  transition: background-color 0.2s, color 0.2s;
  height: 35px;

  &:hover {
    background-color: #ff7080;
    cursor: pointer;
    color: white;
  }
`;

export const PreviousButton = styled.button`
  background-color: #efefef;
  border-radius: 50px;
  border: none;
  color: black;
  padding: 10px 5px 10px 5px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  width: 50%;
  transition: background-color 0.2s, color 0.2s;
  height: 45px;

  &:hover {
    background-color: #939393;
    cursor: pointer;
    color: white;
  }
`;

export const QuestionsHeader = styled.p`
  font-size: 14pt;
  color: white;
`;

export const ResultsBodyBox1 = styled.div`
  border-radius: 25px;
  background-color: #fff2f3;
  width: 100%;
  height: auto;
  margin-top: 25px;
  margin-bottom: 20px;
`;

export const ResultsBodyBox1TopContent = styled.div`
  height: auto;
  overflow: auto;
  line-height: 2;
  border-radius: 25px 25px 0 0;
  padding: 20px;
`;

export const ResultsBodyBox1BottomContent = styled.div`
  height: auto;
  font-size: 12pt;
  background-color: white;
  border-radius: 0 0 25px 25px;
  ${(props) => {
    switch (props.device) {
      case "mobile":
        return "padding: 14px;";
      case "browser":
        return "padding: 20px;";
    }
  }}
`;

export const Divider = styled.div`
  margin-top: 50px;
  display: table;
  white-space: nowrap;
  &:before,
  &:after {
    border-top: 1px solid white;
    content: "";
    display: table-cell;
    position: relative;
    width: 45%;
  }
`;

export const DividerBox = styled.div`
  border-radius: 30px;
  border-width: 1px;
  border-color: white;
  border-style: solid;
  width: 160px;
  height: 40px;
  margin: auto;
  position: relative;
  bottom: 20px;
`;

export const DividerBoxText = styled.div`
  font-size: 16pt;
  line-height: 35px;
  color: white;
  text-align: center;
`;

export const BoxShadow = styled.div`
  box-shadow: -4px 4px 8px rgba(0, 0, 0, 0.15);
`;

export const SkillsBox = styled.div`
  border-radius: 30px;
  border-width: 1px;
  border-color: #e0e0e0;
  border-style: solid;
  padding: 10px;
  margin-bottom: 10px;
  padding-right: 20px;
  ${(props) => {
    switch (props.device) {
      case "mobile":
        return "padding-left: 18px;";
      case "browser":
        return "padding-left: 25px;";
    }
  }}
`;

export const Footer = styled.div`
  background-color: white;
  /* padding: 20px; */
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 60px;
  width: 100%;
  margin: 0;
  margin-bottom: 0px;
`;

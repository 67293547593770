import React, { Component } from "react";
import WelcomImage from "../../assets/images/imgHomePageGraphic.png";
import {
  TopBar,
  TopBarContent,
  LandingBody,
  LandingBodyBox,
  LandingBodyBoxContent,
  WelcomeImage,
  Centralise,
  Button,
} from "../styles/Theme";
import { withRouter } from "react-router-dom";
import "../styles/style.scss";
import { DateInput } from "semantic-ui-calendar-react";
import {
  Grid,
  Icon,
  Select,
  Dimmer,
  Header,
  Message,
  Sidebar,
  SidebarPusher,
  Menu,
  Input,
  Modal,
} from "semantic-ui-react";
import "moment/locale/zh-cn";
import moment from "moment";
import { isBrowser } from "react-device-detect";
import { DesktopOrLaptop, TabletOrMobile } from "../MediaQuery";
import { withTranslation } from "react-i18next";
import { Dropdown } from "semantic-ui-react";
import i18n from "i18next";
import Footer from "../Footer";

class Landing extends Component {
  constructor(props) {
    super();
    this.state = {
      dateSel: "",
      sexSel: "",
      active: false,
      message: "",
      visible: false,
      locked: true,
      agentID: "",
      activationCode: "",
      isModalOpen: true, // The modal will be open by default when the page loads
      isAccessGranted: false, // By default, access is not granted
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSexChange = this.handleSexChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleVisible = this.handleVisible.bind(this);
  }

  handleOpen = () => this.setState({ active: true });
  handleClose = () => this.setState({ active: false });

  componentDidMount() {
    document.body.style.backgroundColor = "#f1f1f1";
  }

  codeToText(languageCode) {
    if (languageCode === "zh-CN") {
      return "简体中文 (Chinese - Simplified)";
    } else if (languageCode === "zh-TR") {
      return "繁體中文 (Chinese - Traditional)";
    }
    return;
  }

  handleActivationCodeChange = (event) => {
    this.setState({ activationCode: event.target.value });
  };

  handleModalClose = () => {
    const { t } = this.props;
    if (this.state.activationCode === "NS2023") {
      this.setState({ isModalOpen: false, isAccessGranted: true });
    } else {
      alert(t("index.incorrectActivationCode"));
    }
  };

  handleChange = (event) => {
    const lang = event.currentTarget.textContent;

    if (lang === "简体中文 (Chinese - Simplified)") {
      i18n.changeLanguage("zh-CN");
    } else if (lang === "繁體中文 (Chinese - Traditional)") {
      i18n.changeLanguage("zh-TR");
    }
  };

  handleAgentIDChange = (event) => {
    this.setState({ agentID: event.target.value });
  };

  navigateToQuestionnaire = (index) => {
    this.props.history.push(
      `/questionnaire/${index}/${this.state.sexSel}/${this.state.dateSel}`
    );
  };

  getDaysApart() {
    const dateArray = this.state.dateSel.split("-");
    var dob = new Date(dateArray[2], dateArray[1], dateArray[0]);
    var today = new Date();

    // To calculate the time difference of two dates
    var Difference_In_Time = today.getTime() - dob.getTime();

    // To calculate the no. of days between two dates
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);

    return Difference_In_Days;
  }

  handleMobileChange(lang) {
    if (lang === "简体中文 (Chinese - Simplified)") {
      i18n.changeLanguage("zh-CN");
      this.handleVisible(false);
    } else if (lang === "繁體中文 (Chinese - Traditional)") {
      i18n.changeLanguage("zh-TR");
      this.handleVisible(false);
    }
  }

  onSubmit(event) {
    const sexSel = this.state.sexSel;
    const dateSel = this.state.dateSel;
    const { t } = this.props;
    if (sexSel.length === 0) {
      this.setState({ message: t("index.genderMessage") });
      this.setState({ active: true });
    } else if (dateSel.length === 0) {
      this.setState({ message: t("index.birthdayMessage") });
      this.setState({ active: true });
    } else {
      const monthDay = 30.436875;
      const exactDay = this.getDaysApart();

      // 3M16D ~ 5M15D
      if (3 * monthDay + 16 <= exactDay && exactDay <= 5 * monthDay + 15) {
        this.navigateToQuestionnaire(1);
        // 5M16D ~ 8M15D
      } else if (
        5 * monthDay + 15 < exactDay &&
        exactDay <= 8 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(2);
        // 8M16D ~ 11M15D
      } else if (
        8 * monthDay + 15 < exactDay &&
        exactDay <= 11 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(3);
        // 11M16D ~ 1Y2M15D
      } else if (
        11 * monthDay + 15 < exactDay &&
        exactDay <= 365 + 2 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(4);

        // 1Y2M16D ~ 1Y5M15D
      } else if (
        365 + 2 * monthDay + 15 < exactDay &&
        exactDay <= 365 + 5 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(5);
        // 1Y5M16D ~ 1Y11M15D
      } else if (
        365 + 5 * monthDay + 15 < exactDay &&
        exactDay <= 365 + 11 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(6);
        // 1Y11M16D ~ 2Y5M15D
      } else if (
        365 + 11 * monthDay + 15 < exactDay &&
        exactDay <= 2 * 365 + 5 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(7);
        // 2Y5M16D ~ 2Y11M15D
      } else if (
        2 * 365 + 5 * monthDay + 15 < exactDay &&
        exactDay <= 2 * 365 + 11 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(8);
        // 2Y11M16D ~ 3Y5M15D
      } else if (
        2 * 365 + 11 * monthDay + 15 < exactDay &&
        exactDay <= 3 * 365 + 5 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(9);
        // 3Y5M16D ~ 3Y11M15D
      } else if (
        3 * 365 + 5 * monthDay + 15 < exactDay &&
        exactDay <= 3 * 365 + 11 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(10);
        // 3Y11M16D ~ 4Y11M15D
      } else if (
        3 * 365 + 11 * monthDay + 16 <= exactDay &&
        exactDay <= 4 * 365 + 11 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(11);
        // 4Y11M16D ~ 5Y11M15D
      } else if (
        4 * 365 + 11 * monthDay + 15 < exactDay &&
        exactDay <= 5 * 365 + 11 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(12);
        // 5Y11M16D ~ 6Y11M15D
      } else if (
        5 * 365 + 11 * monthDay + 15 < exactDay &&
        exactDay <= 6 * 365 + 11 * monthDay + 15
      ) {
        this.navigateToQuestionnaire(13);
      } else if (6 * 365 + 11 * monthDay + 15 < exactDay) {
        this.setState({
          message: t("index.outOfRange1"),
        });
        this.setState({ active: true });
      } else {
        this.setState({
          message: t("index.outOfRange2"),
        });
        this.setState({ active: true });
      }
    }
  }

  handleDateChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value });
    }
  };

  handleSexChange(event) {
    const sex = event.currentTarget.textContent;
    this.setState({ sexSel: sex });
  }

  handleVisible(boolean) {
    this.setState({ visible: boolean });
  }

  render() {
    const active = this.state.active;
    const message = this.state.message;
    const { t } = this.props;

    const sexOptions = [
      { key: "男", value: "男", text: "男" },
      { key: "女", value: "女", text: "女" },
    ];
    const intro_paragraph_1 = t("index.introPara1");
    const intro_paragraph_2 = t("index.introPara2");
    const intro_paragraph_3 = t("index.introPara3");

    const languageOptions = [
      {
        key: "繁體中文 (Chinese - Traditional)",
        text: "繁體中文 (Chinese - Traditional)",
        value: "繁體中文 (Chinese - Traditional)",
      },
      {
        key: "简体中文 (Chinese - Simplified)",
        text: "简体中文 (Chinese - Simplified)",
        value: "简体中文 (Chinese - Simplified)",
      },
    ];

    return (
      <>
        {!this.state.isAccessGranted ? (
          <Modal
            open={this.state.isModalOpen}
            onClose={this.handleModalClose}
            closeOnDimmerClick={false}
            style={{ padding: "20px", margin: "20px" }}
          >
            <Modal.Header>{t("index.enterActivationCode")}</Modal.Header>
            <Modal.Content>
              <Input
                type="text"
                placeholder={t("index.activationCode")}
                value={this.state.activationCode}
                onChange={this.handleActivationCodeChange}
              />
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.handleModalClose}>Submit</Button>
            </Modal.Actions>
          </Modal>
        ) : null}
        <div>
          <Dimmer active={active} onClickOutside={this.handleClose} page>
            <Header as="h2" icon inverted>
              <Message warning size="small">
                <Message.Header> {t("index.warning")}</Message.Header>

                <p>{message}</p>

                <Button onClick={this.handleClose}>{t("index.back")}</Button>
                <p></p>

                <Button
                  onClick={(e) => {
                    window.open(
                      "http://www.qierbao.com/contact",
                      "_blank" // <- This is what makes it open in a new window.
                    );
                  }}
                >
                  {t("index.contact")}
                </Button>
              </Message>
            </Header>
          </Dimmer>
          <Sidebar
            as={Menu}
            animation="overlay"
            icon="labeled"
            inverted
            onHide={() => this.handleVisible(false)}
            vertical
            visible={this.state.visible}
            width="thin"
            style={{ backgroundColor: "white" }}
          >
            <br />
            <a
              className="waves-effect waves-light btn-medium teal-text text-accent-4"
              onClick={() => this.handleVisible(false)}
            >
              <Icon name="angle double left" />
              <b>文 | A</b>
            </a>
            <br />
            <br />
            <Menu.Item
              as="a"
              style={{ color: "black" }}
              onClick={() =>
                this.handleMobileChange("繁體中文 (Chinese - Traditional)")
              }
            >
              繁體中文 (Chinese - Traditional)
            </Menu.Item>

            <Menu.Item
              as="a"
              style={{ color: "black" }}
              onClick={() =>
                this.handleMobileChange("简体中文 (Chinese - Simplified)")
              }
            >
              简体中文 (Chinese - Simplified)
            </Menu.Item>
          </Sidebar>

          <SidebarPusher>
            <TopBar page="index">
              <DesktopOrLaptop>
                <Dropdown
                  button
                  className="icon left"
                  labeled
                  icon="world"
                  options={languageOptions}
                  text={this.codeToText(this.props.i18n.language)}
                  onChange={this.handleChange}
                  style={{
                    display: "float",
                    float: "left",
                    marginTop: "0.8rem",

                    marginLeft: "2rem",
                  }}
                />
              </DesktopOrLaptop>

              <TabletOrMobile>
                <div className="sideMenu"></div>
                <Icon
                  style={{
                    display: "float",
                    float: "left",
                    marginTop: "1.2rem",
                    paddingLeft: "2rem",
                    cursor: "pointer",
                    paddingBottom: "0.5rem",
                  }}
                  onClick={() => this.handleVisible(true)}
                  name="language"
                  color="black"
                  size="big"
                />
              </TabletOrMobile>
              <TopBarContent device={isBrowser ? "browser" : "mobile"}>
                <p
                  style={
                    ({ display: "inline-block" },
                    isBrowser
                      ? { marginRight: "22rem" }
                      : { marginRight: "1rem", marginTop: "0.2rem" })
                  }
                >
                  {t("index.topbar")}
                </p>
              </TopBarContent>
            </TopBar>

            <LandingBody page="grey">
              <Grid>
                <Grid.Row>
                  <Grid.Column width={isBrowser ? 5 : 1}></Grid.Column>

                  <Grid.Column width={isBrowser ? 6 : 14}>
                    <div>
                      <WelcomeImage
                        device={isBrowser ? "browser" : "mobile"}
                        src={WelcomImage}
                      />
                    </div>
                  </Grid.Column>

                  <Grid.Column width={isBrowser ? 5 : 1}></Grid.Column>
                </Grid.Row>
                <Grid.Row style={{ padding: 0 }}>
                  <Grid.Column width={isBrowser ? 5 : 1}></Grid.Column>
                  <Grid.Column width={isBrowser ? 6 : 14}>
                    <div>
                      <LandingBodyBox>
                        <LandingBodyBoxContent>
                          <Centralise>
                            <strong>
                              <h3>{t("index.introTitle")}</h3>
                            </strong>
                          </Centralise>
                          <br />
                          <p>{intro_paragraph_1}</p>
                          <p>{intro_paragraph_2}</p>
                          <Centralise>
                            <i>
                              <p style={{ fontWeight: "lighter" }}>
                                {intro_paragraph_3}
                              </p>
                            </i>
                          </Centralise>
                        </LandingBodyBoxContent>
                        <br />
                        <Centralise>
                          <DesktopOrLaptop>
                            <Grid columns={2}>
                              <Grid.Column>
                                <Select
                                  placeholder={t("index.genderPlaceholder")}
                                  options={sexOptions}
                                  onChange={this.handleSexChange}
                                />
                              </Grid.Column>
                              <Grid.Column>
                                <DateInput
                                  className="browser-default "
                                  name="dateSel"
                                  maxDate={moment()}
                                  closable
                                  clearable
                                  autoComplete="off"
                                  placeholder={t("index.birthdayPlaceholder")}
                                  value={this.state.dateSel}
                                  clearIcon={<Icon name="remove" color="red" />}
                                  iconPosition="left"
                                  onChange={this.handleDateChange}
                                  animation="none"
                                  startMode="year"
                                  localization="zh-cn"
                                  dateFormat="MM-DD-YYYY"
                                />
                              </Grid.Column>
                            </Grid>
                            <Grid columns={1}>
                              <Grid.Column>
                                <Input
                                  placeholder={t(
                                    "index.nanshanMemberProjectCode"
                                  )}
                                  value={this.state.agentID}
                                  onChange={this.handleAgentIDChange}
                                />
                              </Grid.Column>
                            </Grid>
                          </DesktopOrLaptop>
                          <TabletOrMobile>
                            <Select
                              placeholder={t("index.genderPlaceholder")}
                              options={sexOptions}
                              onChange={this.handleSexChange}
                              style={{ width: "70%", marginBottom: "1.5rem" }}
                            />
                            <DateInput
                              className="browser-default "
                              name="dateSel"
                              maxDate={moment()}
                              closable
                              clearable
                              placeholder={t("index.birthdayPlaceholder")}
                              value={this.state.dateSel}
                              clearIcon={<Icon name="remove" color="red" />}
                              iconPosition="left"
                              onChange={this.handleDateChange}
                              animation="none"
                              startMode="year"
                              localization="zh-cn"
                              style={{ width: "70%", marginBottom: "1.5rem" }}
                            />
                            <Input
                              placeholder={t("index.nanshanMemberProjectCode")}
                              value={this.state.agentID}
                              onChange={this.handleAgentIDChange}
                              style={{ width: "70%" }}
                            />
                          </TabletOrMobile>
                        </Centralise>
                        <br />
                        <Centralise>
                          <Button onClick={this.onSubmit} type="submit">
                            {t("index.start")}
                          </Button>
                        </Centralise>
                      </LandingBodyBox>
                    </div>
                  </Grid.Column>
                  <Grid.Column width={isBrowser ? 5 : 1}></Grid.Column>
                </Grid.Row>
              </Grid>
              <Footer></Footer>
            </LandingBody>
          </SidebarPusher>
        </div>
      </>
    );
  }
}

export default withRouter(withTranslation()(Landing));

import Taro from "@tarojs/taro";
// import { BASE_API_URL } from '../constants/common'

import id2_7 from "../assets/images/id2-7.png";
import id7_4 from "../assets/images/id7-4.png";
import id8_8 from "../assets/images/id8-8.png";
import id9_5 from "../assets/images/id9-5.png";
import id9_8_9_10 from "../assets/images/id9-8-9-10.png";
import id10_5 from "../assets/images/id10-5.png";
import id10_9 from "../assets/images/id10-9.png";
import id10_8_10 from "../assets/images/id10-8-10.png";
import id11_6 from "../assets/images/id11-6.png";
import id11_7 from "../assets/images/id11-7.png";
import id11_9 from "../assets/images/id11-9.png";
import id11_10 from "../assets/images/id11-10.png";
import id12_5 from "../assets/images/id12-5.png";
import id12_7 from "../assets/images/id12-7.png";
import id12_9 from "../assets/images/id12-9.png";
import id13_3 from "../assets/images/id13-3.png";
import id13_4 from "../assets/images/id13-4.png";
import id13_6 from "../assets/images/id13-6.png";
import id13_7 from "../assets/images/id13-7.png";

export default {
  fetchQuestionnaires: () => {
    const data = [
      {
        id: 1,
        title: "检核表_4个月",
        description: "学龄前儿童发展检核表\n4个月 （３个月16天～五个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set1.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set1.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set1.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set1.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set1.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 2,
            title: "questions.set1.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set1.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question!
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set1.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question!
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 5,
            title: "questions.set1.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set1.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question!
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 7,
            title: "questions.set1.questions.7",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set1.questions.8",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 9,
            title: "questions.set1.questions.9",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question!
              },
            ],
          },
          {
            id: 10,
            title: "questions.set1.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set1.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set1.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question!
              },
            ],
          },
        ],
      },
      {
        id: 2,
        title: "检核表_6个月",
        description: "学龄前儿童发展检核表\n6个月 （5个月16天～八个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set2.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set2.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set2.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set2.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set2.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 2,
            title: "questions.set2.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set2.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set2.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 5,
            title: "questions.set2.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set2.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 7,
            title:
              // TODO: need to change the content here
              "questions.set2.questions.7",
            single: true,
            image: id2_7,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set2.questions.8",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred qiestion !
              },
            ],
          },
          {
            id: 9,
            title: "questions.set2.questions.9",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set2.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question !
              },
            ],
          },
          {
            id: 11,
            title: "questions.set2.questions.11",
            image: null,
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set2.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
        ],
      },
      {
        id: 3,
        title: "检核表_9个月",
        description: "学龄前儿童发展检核表\n9个月 （8个月16天～11个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set3.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set3.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set3.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set3.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set3.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 2,
            title: "questions.set3.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 3,
            title: "questions.set3.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set3.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 5,
            title: "questions.set3.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set3.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set3.questions.7",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 8,
            title: "questions.set3.questions.8",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 9,
            title: "questions.set3.questions.9",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set3.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question !
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set3.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set3.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 4,
        title: "检核表_1岁",
        description: "学龄前儿童发展检核表\n1岁 （11个月16天～1岁2个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set4.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set4.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set4.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set4.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set4.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 2,
            title: "questions.set4.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set4.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set4.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 5,
            title: "questions.set4.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set4.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 7,
            title: "questions.set4.questions.7",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set4.questions.8",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 9,
            title: "questions.set4.questions.9",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set4.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question !
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set4.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question !
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 5,
        title: "检核表_1岁3个月",
        description:
          "学龄前儿童发展检核表\n1岁3个月（1岁2个月16天～1岁5个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set5.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set5.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set5.milestone.4",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set5.milestone.5",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set5.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 2,
            title: "questions.set5.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set5.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set5.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 5,
            title: "questions.set5.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set5.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set5.questions.7",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set5.questions.8",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 9,
            title: "questions.set5.questions.9",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 10,
            title: "questions.set5.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set5.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question !
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set5.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question !
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 6,
        title: "检核表_1岁6个月",
        description:
          "学龄前儿童发展检核表\n1岁6个月（1岁5个月16天～1岁11个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set6.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set6.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set6.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set6.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set6.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 2,
            title: "questions.set6.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set6.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question !
              },
            ],
          },
          {
            id: 4,
            title: "questions.set6.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 5,
            title: "questions.set6.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set6.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set6.questions.7",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 8,
            title: "questions.set6.questions.8",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 9,
            title: "questions.set6.questions.9",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set6.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set6.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question !
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set6.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question !
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 13,
            title: "questions.set6.questions.13",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question !
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 7,
        title: "检核表_2岁",
        description: "学龄前儿童发展检核表\n2岁（1岁11个月16天～2岁5个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set7.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set7.milestone.1",
          },
          {
            name: "questions.movement",
            text: "questions.set7.milestone.1",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set7.milestone.1",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set7.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 2,
            title: "questions.set7.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set7.questions.3", //（娃娃语如“ㄇㄢㄇㄢ”为吃、汪汪为狗亦可）",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question !
              },
            ],
          },
          {
            id: 4,
            title: "questions.set7.questions.4",
            single: true,
            image: id7_4,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question !
              },
            ],
          },
          {
            id: 5,
            title: "questions.set7.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set7.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set7.questions.7",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 8,
            title: "questions.set7.questions.8",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 9,
            title: "questions.set7.questions.9",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set7.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set7.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 8,
        title: "检核表_2岁6个月",
        description:
          "学龄前儿童发展检核表\n2岁6个月（2岁5个月16天～2岁11个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set8.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set8.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set8.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set8.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set8.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 2,
            title: "questions.set8.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 3,
            title: "questions.set8.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set8.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question !
              },
            ],
          },
          {
            id: 5,
            title: "questions.set8.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question !
              },
            ],
          },
          {
            id: 6,
            title: "questions.set8.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set8.questions.7",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set8.questions.8",
            single: true,
            image: id8_8,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 9,
            title: "questions.set8.questions.9",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 10,
            title: "questions.set8.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set8.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set8.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 9,
        title: "检核表_3岁",
        description: "学龄前儿童发展检核表\n3岁（2岁11个月16天～3岁5个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set9.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set9.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set9.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set9.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set9.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 2,
            title: "questions.set9.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set9.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set9.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 5,
            title: "questions.set9.questions.5",
            single: true,
            image: id9_5,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set9.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set9.questions.7",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set9.questions.8",
            single: true,
            image: id9_8_9_10,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 9,
            title: "questions.set9.questions.9",
            single: true,
            image: id9_8_9_10,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set9.questions.10",
            single: true,
            image: id9_8_9_10,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set9.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set9.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 13,
            title: "questions.set9.questions.13",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 10,
        title: "检核表_3岁6个月",
        description:
          "学龄前儿童发展检核表\n3岁6个月（3岁5个月16天～3岁11个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set10.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set10.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set10.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set10.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set10.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 2,
            title: "questions.set10.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set10.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set10.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 5,
            title: "questions.set10.questions.5",
            single: true,
            image: id10_5,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set10.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set10.questions.7",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set10.questions.8",
            single: true,
            image: id10_8_10,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 9,
            title: "questions.set10.questions.9",
            single: true,
            image: id10_9,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set10.questions.10",
            single: true,
            image: id10_8_10,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set10.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set10.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 13,
            title: "questions.set10.questions.13",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 11,
        title: "检核表_4岁",
        description:
          "学龄前儿童发展检核表\n4岁（3岁11个月16天～4岁11个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set11.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set11.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set11.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set11.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set11.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 2,
            title: "questions.set11.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set11.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set11.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 5,
            title: "questions.set11.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 6,
            title: "questions.set11.questions.6",
            single: true,
            image: id11_6,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set11.questions.7",
            single: true,
            image: id11_7,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set11.questions.8",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 9,
            title: "questions.set11.questions.9",
            single: true,
            image: id11_9,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set11.questions.10",
            single: true,
            image: id11_10,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set11.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set11.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 13,
            title: "questions.set11.questions.13",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 1,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 12,
        title: "检核表_5岁",
        description:
          "学龄前儿童发展检核表\n5岁（4岁11个月16天～5岁11个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set12.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set12.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set12.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set12.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set12.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 2,
            title: "questions.set12.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 3,
            title: "questions.set12.questions.3",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set12.questions.4",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 5,
            title: "questions.set12.questions.5",
            single: true,
            image: id12_5,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 6,
            title: "questions.set12.questions.6",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set12.questions.7",
            single: true,
            image: id12_7,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set12.questions.8",
            single: true,
            image: id13_6,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 9,
            title: "questions.set12.questions.9",
            single: true,
            image: id12_9,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set12.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set12.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set12.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 13,
            title: "questions.set12.questions.13",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
      {
        id: 13,
        title: "检核表_6岁",
        description:
          "学龄前儿童发展检核表\n6岁（5岁11个月16天～6岁11个月15天）",
        duration: 5,
        imageUrl: "",
        milestone: [
          {
            name: "questions.language",
            text: "questions.set13.milestone.1",
          },
          {
            name: "questions.motion",
            text: "questions.set13.milestone.2",
          },
          {
            name: "questions.movement",
            text: "questions.set13.milestone.3",
          },
          {
            name: "questions.interpersonal",
            text: "questions.set13.milestone.4",
          },
        ],
        questions: [
          {
            id: 1,
            title: "questions.set13.questions.1",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 2,
            title: "questions.set13.questions.2",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 3,
            title: "questions.set13.questions.3",
            single: true,
            image: id13_3,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 4,
            title: "questions.set13.questions.4",
            single: true,
            image: id13_4,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 5,
            title: "questions.set13.questions.5",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 6,
            title: "questions.set13.questions.6",
            single: true,
            image: id13_6,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 7,
            title: "questions.set13.questions.7",
            single: true,
            image: id13_7,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 8,
            title: "questions.set13.questions.8",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 2, // starred question
              },
            ],
          },
          {
            id: 9,
            title: "questions.set13.questions.9",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 0,
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 1,
              },
            ],
          },
          {
            id: 10,
            title: "questions.set13.questions.10",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 11,
            title: "questions.set13.questions.11",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 12,
            title: "questions.set13.questions.12",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
          {
            id: 13,
            title: "questions.set13.questions.13",
            single: true,
            image: null,
            options: [
              {
                id: 1,
                key: "A",
                value: "是",
                score: 2, // starred question
              },
              {
                id: 2,
                key: "B",
                value: "否",
                score: 0,
              },
            ],
          },
        ],
      },
    ];
    // mock delay 1000ms
    return new Promise((resolve) => setTimeout(() => resolve(data), 1000));
  },
  completeQuestionnaire: (finalScore) => {
    const result = {
      title: "result",
      finalScore: finalScore,
    };
    return new Promise((resolve) => setTimeout(() => resolve(result), 1000));
  },
};

import React, { Component, Suspense } from "react";
import { Dimmer, Segment } from "semantic-ui-react";
import { BrowserRouter } from "react-router-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Index from "./webpages/index";
import Questionnaire from "./webpages/questionnaire";
import Results from "./webpages/results";
import i18n from "i18next";

class WebApp extends Component {
  render() {
    i18n.changeLanguage("zh-TR");

    const Loader = () => (
      <Segment>
        <Dimmer active>
          <Loader />
        </Dimmer>
      </Segment>
    );
    return (
      <Suspense fallback={Loader}>
        <BrowserRouter>
          <Switch>
            <Router>
              <div className="App">
                <Route exact path="/" component={Index} />
                <Route
                  exact
                  path="/questionnaire/:questionnaireId/:sexSel/:dateSel"
                  component={Questionnaire}
                />
                <Route
                  exact
                  path="/results/:questionnaireId/:finalScore/:sexSel/:dateSel"
                  component={Results}
                />
              </div>
            </Router>
          </Switch>
        </BrowserRouter>
      </Suspense>
    );
  }
}

export default WebApp;
